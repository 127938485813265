import styled from 'styled-components';

import { Checkbox, Dropdown } from '../';

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
`;

export const StyledCheckbox = styled(Checkbox)`
  .nextui-checkbox-container {
    border-radius: 0.3125rem;

    :disabled {
      cursor: not-allowed;
    }
  }

  .nextui-checkbox-text {
    padding-left: 0.5rem;
  }
`;
