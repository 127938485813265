import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';

import Modal, { ModalProps } from '../Modal';

export interface ActionModalComponentProps {
  ref?: Ref<any>;
  onLoadingChanged?: (loading: boolean) => void;
  setDisabledSubmitButton?: (disabled: boolean) => void;
  onFinish?: () => void;
}

interface ActionModalProps<T> extends Omit<ModalProps, 'open' | 'onClose' | 'children'> {
  Component: React.FunctionComponent<ActionModalComponentProps & T>;
  componentProps?: T;
  onFinish?: () => void;
  onClosed?: () => void;
}

const ActionModal = forwardRef(
  // eslint-disable-next-line comma-spacing
  <T,>({ Component, componentProps, onFinish, onClosed, ...rest }: ActionModalProps<T>, ref: Ref<any>) => {
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [disabledSubmitButton, setDisabledSubmitButton] = React.useState(false);
    const componentRef = React.useRef(null);

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true);
      },
      close: () => {
        setOpen(false);
      },
      submit: () => {
        componentRef.current.submit();
      },
    }));

    const handleFinish = () => {
      setOpen(false);
      onFinish?.();
    };

    return (
      <Modal
        open={isOpen}
        loading={loading}
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            onClosed?.();
          }, 100);
        }}
        onSubmit={() => componentRef.current.submit()}
        disabledSubmitButton={disabledSubmitButton}
        {...rest}
      >
        <Component
          ref={componentRef}
          onLoadingChanged={setLoading}
          setDisabledSubmitButton={setDisabledSubmitButton}
          onFinish={handleFinish}
          {...componentProps}
        />
      </Modal>
    );
  },
);

export default ActionModal as <T>(props: ActionModalProps<T> & { ref: Ref<any> }) => JSX.Element;
