import { FormElement, Input as NextInput } from '@nextui-org/react';
import React, { ChangeEvent, ComponentProps, FocusEvent, forwardRef, KeyboardEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import HelpTooltip from '~/components/HelpTooltip';
import { applyStyle } from '~/overwrites/components/Input/Input.styles';
import { isTouchDevice } from '~/utils/helpers';

const Input = applyStyle(NextInput);

Input.Password = applyStyle(NextInput.Password);

const HelperWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export interface InputProps extends ComponentProps<typeof NextInput> {
  iconLeft?: ReactNode;
  error?: string;
  allowOnlyLetters?: boolean;
  helpText?: string | ReactNode;
}

const InputErrorWrapper = forwardRef((props: InputProps, ref) => {
  const isCustomLabel = props.label && typeof props.label !== 'string';
  const label = props.required && !isCustomLabel ? `${props.label} *` : props.label;
  const [readOnlyTemp, setReadOnlyTemp] = useState(false);

  const allowOnlyLetters = props.allowOnlyLetters;

  const onChangeOnlyLetter = (event: ChangeEvent<FormElement>) => {
    event.target.value = event.target.value.replace(/[^a-zA-Z-. ]/g, '');
    props.onChange?.(event);
  };

  const onKeyDownOnlyLetter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!/^[a-zA-Z-. ]*$/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onChange = allowOnlyLetters ? onChangeOnlyLetter : props.onChange;
  const onKeyDown = allowOnlyLetters ? onKeyDownOnlyLetter : props.onKeyDown;
  const onBlurLocal = (e: FocusEvent<FormElement, Element>) => {
    if (isTouchDevice()) {
      setReadOnlyTemp(true);
      setTimeout(() => {
        setReadOnlyTemp(false);
        props.onBlur?.(e);
      }, 200);
    } else {
      props.onBlur?.(e);
    }
  };

  const labelWithHelper = (
    <HelperWrapper>
      {label} {<HelpTooltip $helpText={props.helpText} />}
    </HelperWrapper>
  );

  return (
    <Input
      {...props}
      label={props.helpText && !isCustomLabel ? labelWithHelper : label}
      initialValue={props.value}
      ref={ref}
      contentLeft={props.iconLeft || null}
      status={props.error ? 'error' : 'default'}
      helperColor={props.error ? 'error' : 'default'}
      helperText={props.error}
      onChange={onChange}
      onKeyDown={onKeyDown}
      readOnly={props.readOnly || readOnlyTemp}
      onBlur={onBlurLocal}
    />
  );
}) as any;

InputErrorWrapper.Password = forwardRef((props: InputProps, ref) => {
  const isCustomLabel = props.label && typeof props.label !== 'string';
  const label = props.required && !isCustomLabel ? `${props.label} *` : props.label;
  const [readOnlyTemp, setReadOnlyTemp] = useState(props.autoComplete === 'off');

  const labelWithHelper = (
    <HelperWrapper>
      {label} {<HelpTooltip $helpText={props.helpText} />}
    </HelperWrapper>
  );

  const onFocusLocal = (e: FocusEvent<FormElement, Element>) => {
    setReadOnlyTemp(false);
    props.onFocus(e);
  };

  return (
    <Input.Password
      {...props}
      ref={ref}
      label={props.helpText && !isCustomLabel ? labelWithHelper : label}
      status={props.error ? 'error' : 'default'}
      helperColor={props.error ? 'error' : 'default'}
      helperText={props.error}
      readOnly={props.readOnly || readOnlyTemp}
      onFocus={onFocusLocal}
    />
  );
});

export default InputErrorWrapper;
