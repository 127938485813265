const customDomainCobrandDomains = {
  affinityx: ['app.audienceinsights-staging.com', 'app.audienceinsights.ax'],
};

export const cobrandByCustomDomain = Object.entries(customDomainCobrandDomains).reduce((map, [cobrand, domains]) => {
  domains.forEach((domain) => {
    map[domain] = cobrand;
  });
  return map;
}, {} as Record<string, string>);
